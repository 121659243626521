import React from 'react';
import './style.less';
import {Carousel} from "antd";
import {graphql, StaticQuery} from "gatsby";
import {LocaleContext} from "../../context/LocaleContext";
import Img from "gatsby-image";

class MyTripSlides extends React.Component {

    // constructor(props){
    //     super(props);
    // }

    getImageTag(imageLocalFile) {
        return imageLocalFile.childImageSharp ? <Img fluid={imageLocalFile.childImageSharp.fluid} /> : <img src={imageLocalFile.url} alt=""/>;
    }

    render() {

        const settings = {
            dots: true,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            fade: true,
            swipeToSlide: true,
            infinite: false
        };

        const locale = this.context.locale;
        let lang = {};
        switch(locale){
            case 'en':
            default:

                lang = {
                    slide1image: this.getImageTag(this.props.data.trip_slide_1_image_en.localFile),
                    slide1header: this.props.data.trip_slide_1_header_en,
                    slide1content: this.props.data.trip_slide_1_content_en,
                    slide2image: this.getImageTag(this.props.data.trip_slide_2_image_en.localFile),
                    slide2header: this.props.data.trip_slide_2_header_en,
                    slide2content: this.props.data.trip_slide_2_content_en,
                    slide3image: this.getImageTag(this.props.data.trip_slide_3_image_en.localFile),
                    slide3header: this.props.data.trip_slide_3_header_en,
                    slide3content: this.props.data.trip_slide_3_content_en
                };
                break;
            case 'cy':
                lang = {
                    slide1image: this.getImageTag(this.props.data.trip_slide_1_image_cy.localFile),
                    slide1header: this.props.data.trip_slide_1_header_cy,
                    slide1content: this.props.data.trip_slide_1_content_cy,
                    slide2image: this.getImageTag(this.props.data.trip_slide_2_image_cy.localFile),
                    slide2header: this.props.data.trip_slide_2_header_cy,
                    slide2content: this.props.data.trip_slide_2_content_cy,
                    slide3image: this.getImageTag(this.props.data.trip_slide_3_image_cy.localFile),
                    slide3header: this.props.data.trip_slide_3_header_cy,
                    slide3content: this.props.data.trip_slide_3_content_cy
                };
                break;
        }

        return (
            <Carousel className="image-slider" {...settings}>
                <div>
                    {lang.slide1image}
                    <h3>{lang.slide1header}</h3>
                    <p>{lang.slide1content}</p>
                </div>
                <div>
                    {lang.slide2image}
                    <h3>{lang.slide2header}</h3>
                    <p>{lang.slide2content}</p>
                </div>
                <div>
                    {lang.slide3image}
                    <h3>{lang.slide3header}</h3>
                    <p>{lang.slide3content}</p>
                </div>
            </Carousel>
        );
    }
}

MyTripSlides.contextType = LocaleContext;

export default () => (
    <StaticQuery
        query={graphql`
            query MyTripSlidesQuery {
              allWordpressAcfOptions {
                edges {
                  node {
                    id
                    options {
                      trip_slide_1_image_en {
                        localFile {
                          childImageSharp {
                            fluid {
                              ...GatsbyImageSharpFluid
                            }
                          }
                          url
                        }
                      }
                      trip_slide_1_image_cy {
                        localFile {
                          childImageSharp {
                            fluid {
                              ...GatsbyImageSharpFluid
                            }
                          }
                          url
                        }
                      }
                      trip_slide_1_header_en
                      trip_slide_1_header_cy
                      trip_slide_1_content_en
                      trip_slide_1_content_cy

                      trip_slide_2_image_en {
                        localFile {
                          childImageSharp {
                            fluid {
                              ...GatsbyImageSharpFluid
                            }
                          }
                          url
                        }
                      }
                      trip_slide_2_image_cy {
                        localFile {
                          childImageSharp {
                            fluid {
                              ...GatsbyImageSharpFluid
                            }
                          }
                          url
                        }
                      }
                      trip_slide_2_header_en
                      trip_slide_2_header_cy
                      trip_slide_2_content_en
                      trip_slide_2_content_cy

                      trip_slide_3_image_en {
                        localFile {
                          childImageSharp {
                            fluid {
                              ...GatsbyImageSharpFluid
                            }
                          }
                          url
                        }
                      }
                      trip_slide_3_image_cy {
                        localFile {
                          childImageSharp {
                            fluid {
                              ...GatsbyImageSharpFluid
                            }
                          }
                          url
                        }
                      }
                      trip_slide_3_header_en
                      trip_slide_3_header_cy
                      trip_slide_3_content_en
                      trip_slide_3_content_cy
                    }
                  }
                }
              }
            }
        `}
        render={(data) => (
            <MyTripSlides data={data.allWordpressAcfOptions.edges[0].node.options} />
        )}
    />
)
