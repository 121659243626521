import axios from "axios";
const mapsKey = process.env.GATSBY_GOOGLE_MAP_API_KEY;

export const geoLookup = (location, callback) => {
    // append UK to the end because murica
    location = `${location}, UK`;
    axios
        .get(`https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=${mapsKey}`, callback)
        .then(response => {
            // Pluck out the bit we need from the response and pass it to callback
            if(response.data.results) {
                location = location.replace(', UK', '');
                const text = response.data.results[0].geometry.formatted_address ? response.data.results[0].geometry.formatted_address : location;
                const responseObject = {
                    text: text,
                    longitude: response.data.results[0].geometry.location.lng,
                    latitude: response.data.results[0].geometry.location.lat,
                };
                callback(false, responseObject);
            } else {
                callback(true, false);
            }
        })
        .catch(error => {
            // Handle error.
            callback(true, false);
        });
};
