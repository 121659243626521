import React from 'react';
import PropTypes from 'prop-types';
import {getLocation, getDirections} from "../services/api";
import Notifications, {notify} from 'react-notify-toast';
import {geoLookup} from "../services/geocoding";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {trackAddToTrip} from "../services/gtm";

import language from "../../data/language.yaml";

// Context is made up of two things
// Provider - Single as close to top level as possible
// Consumer - Multiple have multiple consumers
export const MyTripContext = React.createContext();

export class MyTripProvider extends React.Component {
    state = {
        tripData: [[]],
        selectedDay: 0,
        totalDistance: 0,
        addingToTrip: false,
        myTripOpen: false,
        tutorialOpen: false,
        showLocationSearch: true,
        premadeItineraryLoad: []
    };

    notificationColor = {
        background: '#AECC53',
        text: '#41525D'
    };

    constructor(props){
        super(props);
        this.loadStateFromStorage();
    }

    loadStateFromStorage = () => {
        if(typeof window !== 'undefined'){
            const storedData = window.localStorage.getItem('S3TD');
            if(storedData) {
                this.parseJsonAsync(storedData).then(data => {
                    this.setState({
                        tripData: data.tripData,
                        selectedDay: data.selectedDay,
                        totalDistance: data.totalDistance,
                        tutorialOpen: false,
                        showLocationSearch: data.tripData[0][0].hasOwnProperty('empty'),
                    });
                })
            }
        }
    };

    loadPremadeItinerary = (days) => {
        // Check they have a start location set first, if not, open the builder and show error
        if(this.state.tripData[0].length === 0){
            this.setState({
                myTripOpen : true
            });
            return notify.show(language.myTrip.setStart[this.props.locale], 'error', 5000);
        }

        // Confirm alert that this will overwrite saved itinerary if set
        if(this.state.tripData.length > 1 || this.state.tripData[0].length > 1) {
            const options = {
                title: language.myTrip.loadItin[this.props.locale],
                message: language.myTrip.loadItin.message[this.props.locale],
                buttons: [
                    {
                        label: language.myTrip.yes[this.props.locale],
                        onClick: () => this.loadItineraryItems(days)
                    },
                    {
                        label: language.myTrip.no[this.props.locale],
                        onClick: () => {
                        }
                    }
                ],
                closeOnEscape: true,
                closeOnClickOutside: true,
            };
            confirmAlert(options);
        } else {
            this.loadItineraryItems(days);
        }
    };

    loadItineraryItems = (days) => {
        // Clear out the existing tripData other than start location
        let tripData = this.state.tripData;
        let newTripData = [];
        newTripData[0] = [];
        newTripData[0][0] = tripData[0][0];

        // Set days ready to load locations
        let x = 0;
        while(x < days.length -1){
            newTripData.push([]);
            x++;
        }

        // Set the state as premadeItineraryDays and trigger the loop to fetch and store in tripdata, then trigger routes update
        this.setState({
            premadeItineraryDays: days,
            tripData: newTripData
        }, this.loadStoredLocation);

    };

    loadStoredLocation = () => {
        let storedDays = this.state.premadeItineraryDays;
        // Find the first location on the last day and work backwards through the array
        if(storedDays.length) {
            let lastDay = storedDays.length - 1;
            if (lastDay === 0 && storedDays[lastDay].locations.length===0) {
                return false;
            } else {
                // We have a location on the last day, get it and remove from state, then pass to process
                let locationIndex = 0; // storedDays[lastDay].locations.length - 1;
                let locationId = storedDays[lastDay].locations[locationIndex];
                storedDays[lastDay].locations.splice(locationIndex, 1);
                // Check if that was the last item on that day
                if(storedDays[lastDay].locations.length===0){
                    storedDays.splice(lastDay, 1);
                }
                this.setState({
                    premadeItineraryDays: storedDays,
                    selectedDay: 0
                });

                // Get the location and store it in tripData on the dayIndex
                getLocation(locationId, (error, response) => {
                    if (error) {
                        return false;
                    }

                    // Get the data we need
                    const location = this.cleanLocationResponseObject(response);

                    // Add it to the locations array
                    let tripData = this.state.tripData;
                    tripData[lastDay].push(location);

                    // set the state then recall the function
                    this.setState({
                        tripData: tripData,
                    }, this.loadStoredLocation);
                });
            }
        } else {
            // we are done here!
            notify.show(language.myTrip.successfullyLoaded[this.props.locale], 'custom', 5000, this.notificationColor);
            this.updateTripRoutes();
        }
    };

    saveStateToStorage = () => {
        if(typeof window !== 'undefined'){
            let data = {
                tripData: this.state.tripData,
                selectedDay: this.state.selectedDay,
                totalDistance: this.state.totalDistance
            };

            this.encodeJsonAsync(data).then(encodedJson => {
                window.localStorage.setItem('S3TD', encodedJson);
            });
        }
    };

    parseJsonAsync = (jsonString) => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(JSON.parse(jsonString))
            })
        })
    };

    encodeJsonAsync = (data) => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(JSON.stringify(data))
            })
        })
    };

    openTrip = () => {
        this.setState({
            myTripOpen : !this.state.myTripOpen,
        });
    };

    openTutorial = () => {
        this.setState({
            tutorialOpen : !this.state.tutorialOpen,
        });
    };

    showSearchBox = () => {
        let showLocationSearch = !this.state.showLocationSearch;
        if (this.state.tripData[0][0].hasOwnProperty('empty')) {
            showLocationSearch = true;
        }
        this.setState({
            showLocationSearch: showLocationSearch
        })
    };

    moveItemDown = (index) => {
        let tripData = this.state.tripData;
        let thisItem = tripData[this.state.selectedDay][index];
        let switchItem = tripData[this.state.selectedDay][index+1];

        tripData[this.state.selectedDay][index+1] = thisItem;
        tripData[this.state.selectedDay][index] = switchItem;

        this.setState({
            tripData: tripData
        }, this.updateTripRoutes);

    };

    moveItemUp = (index) => {
        let tripData = this.state.tripData;
        let thisItem = tripData[this.state.selectedDay][index];
        let switchItem = tripData[this.state.selectedDay][index-1];

        tripData[this.state.selectedDay][index-1] = thisItem;
        tripData[this.state.selectedDay][index] = switchItem;

        this.setState({
            tripData: tripData
        }, this.updateTripRoutes);
    };

    removeLocation = (locationDayIndex) => {
        let tripData = this.state.tripData;
        tripData[this.state.selectedDay].splice(locationDayIndex, 1);
        this.setState({
            tripData: tripData
        }, this.updateTripRoutes)
    };

    addLocation = (locationId) => {
        // if we dont have a starting location set, reject it
        // starting location is a location object at pos 0 on day 0
        let tripData = this.state.tripData;
        if(this.state.tripData[0].length === 0){
            // Open the builder
            tripData[0].push({
                empty: true
            });
            this.setState({
                myTripOpen : true,
                tripData: tripData
            });
            //return notify.show(language.myTrip.setStart[this.props.locale], 'error', 5000);
        }

        // Check how many locations we have for this day, limit it to 10 to stop route spamming
        if(this.state.tripData[this.state.selectedDay].length > 9) {
            return notify.show(language.myTrip.maxActivities[this.props.locale], 'error', 5000);
        }

        if(this.state.addingLocation===true){
            // we are already adding a location, slow your horses
            return false;
        }

        this.setState({
            addingLocation: true
        });

        getLocation(locationId, (error, response) => {
            if (error) {
               return notify.show(language.myTrip.addError[this.props.locale], 'error', 5000);
            }

            // Get the data we need
            const location = this.cleanLocationResponseObject(response);

            // Add it to the locations array
            let tripData = this.state.tripData;
            tripData[this.state.selectedDay].push(location);

            // Track this
            trackAddToTrip(location.member.website_url);

            // set the state then pass to get routes updated
            this.setState({
                tripData: tripData,
            }, this.updateTripRoutes);

            // Show notification
            notify.show(language.myTrip.successfullyAdded.first[this.props.locale] + location.name + language.myTrip.successfullyAdded.second[this.props.locale] + Number(this.state.selectedDay+1), 'custom', 5000, this.notificationColor);
        });
    };

    cleanLocationResponseObject = (response) => {
        const location = response.data.acf;
        location.wordpress_id = response.data.id;
        location.parent_location_type = response.data.parent_location_type;

        // Set routes as null for now
        location.route = null;
        location.routeIndex = null;

        // Clean up the member object
        let member = location.member;
        location.member = {
            featured_image: member.featured_image,
            membership_level: member.membership_level,
            average_visit_duration: member.average_visit_duration,
            booking_url: member.booking_url,
            snippet: member.snippet,
            id: member.id,
            path: member.path,
            website_url: member.website_url,
            name: member.name,
        };
        return location;
    };

    updateTripRoutes = () => {
        let tripData = this.state.tripData;

        // foreach tripdata as dayItems
        tripData.forEach((days, dayIndex) => {
            // foreach dayItems as location
            days.forEach((location, locIndex) => {
                // Skip the first location on day 0, this is the starting point
                if(dayIndex===0 && locIndex===0){
                    return '';
                }

                // we are setting location.route and location.routeIndex, the latter being used to verify if this has changed
                // there will always be a previous location as starting point is required to begin the trip
                let origin = null;
                // if we are day 0, and index 0, the initial location is the starting point
                if(dayIndex===0 && locIndex===0){
                    origin = {
                        latitude: tripData[0][0].latitude,
                        longitude: tripData[0][0].longitude,
                    }
                // if we are day > 0 and index 0, the initial location is set to null so we dont make assumptions on how its being used
                } else if (dayIndex > 0 && locIndex===0){
                    origin = null;
                } else {
                    origin = {
                        latitude: tripData[dayIndex][locIndex-1].latitude,
                        longitude: tripData[dayIndex][locIndex-1].longitude,
                    }
                }

                // Set the destination
                let destination = {
                    latitude: tripData[dayIndex][locIndex].latitude,
                    longitude: tripData[dayIndex][locIndex].longitude,
                };

                if(origin) {
                    // Set an index item that shows the origin,destination that we can compare and see has changed,
                    // if different, fetch the new route and update it
                    // IMPORTANT: MapBox uses lon,lat NOT lat,lon
                    origin = `${origin.longitude},${origin.latitude}`;
                    destination = `${destination.longitude},${destination.latitude}`;
                    let index = `${origin};${destination}`;
                    if (tripData[dayIndex][locIndex].routeIndex !== index) {
                        this.updateDirections(dayIndex, locIndex, origin, destination);
                    }
                } else {
                    // We do not have a source location, set as null
                    this.nullDirections(dayIndex, locIndex);
                }
            })
        });

        // reset the adding location var now we have the routes being processed
        this.setState({
            addingLocation: false
        });
    };

    nullDirections = (day, location) => {
        let tripData = this.state.tripData;
        tripData[day][location].route = null;
        tripData[day][location].routeIndex = null;
        this.setState({
            tripData: tripData,
        }, this.updateTotalDistance);
    };

    updateDirections = (day, location, origin, destination) => {
        getDirections(origin, destination, (error, response) =>{
            if(error) {
                return false;
            }

            if(response.data.data.routes.length === 0){
                return false;
            }

            // If there is an existing route object for this location then deduct the distance from the total
            let index = `${origin};${destination}`;
            let tripData = this.state.tripData;

            //tripData[day][location].route = response.data.data.routes[0];
            tripData[day][location].route = {
                distance: response.data.data.routes[0].distance,
                duration: response.data.data.routes[0].duration
            };
            tripData[day][location].routeIndex = index;
            this.setState({
                tripData: tripData,
            }, this.updateTotalDistance);
        })
    };

    updateTotalDistance = () => {
        let total = 0;
        this.state.tripData.forEach((days) => {
            days.forEach((location) => {
                if (location.route) {
                    total += location.route.distance
                }
            });
        });
        this.setState({
            totalDistance: total
        }, this.saveStateToStorage);
    };

    addDay = () => {
        // Check we are at most 14 days
        if(this.state.tripData.length > 13){
            notify.show(language.myTrip.maxDays[this.props.locale], 'error', 5000);
        } else {
            let tripData = this.state.tripData;
            tripData.push([]);
            let dayCount = tripData.length;
            notify.show(language.myTrip.addedDay.before[this.props.locale] + dayCount + language.myTrip.addedDay.after[this.props.locale], 'custom', 5000, this.notificationColor);
            this.setState({
                tripData: tripData,
                selectedDay: dayCount - 1
            }, this.saveStateToStorage);
        }
    };

    removeDay = () => {
        if(this.state.selectedDay > 0) {
            let tripData = this.state.tripData;
            tripData.splice(this.state.selectedDay, 1);
            this.setState({
                tripData: tripData,
                selectedDay: this.state.selectedDay - 1
            }, this.saveStateToStorage);
        }
    };

    confirmRemoveDay = () => {
        const options = {
            title: language.myTrip.removeDay[this.props.locale] + ' ' + Number(this.state.selectedDay+1) +'?',
            message: language.myTrip.removeDay.sureBefore[this.props.locale] + Number(this.state.selectedDay+1) + language.myTrip.removeDay.sureAfter[this.props.locale],
            buttons: [
                {
                    label: language.myTrip.removeDay.yes[this.props.locale],
                    onClick: () => this.removeDay()
                },
                {
                    label: language.myTrip.removeDay.no[this.props.locale],
                    onClick: () => {}
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        };
        confirmAlert(options);
    };

    setSelectedDay = (selectedDay) => {
        this.setState({
            selectedDay: selectedDay
        })
    };

    setStartLocation = (location, latitude, longitude) => {
        if(latitude && longitude){
            let tripData = this.state.tripData;
            tripData[0][0] = {
                text: language.myTrip.myLocation[this.props.locale],
                latitude: latitude,
                longitude: longitude
            };
            this.setState({
                tripData: tripData,
                showLocationSearch: false
            }, this.updateDirections);
        } else {
            geoLookup(`${location}`, (error, response) => {
                if (error) {
                    return notify.show(language.myTrip.myLocation.error[this.props.locale], 'error', 5000);
                }
                // set this as tripdata[0][0]
                let tripData = this.state.tripData;
                tripData[0][0] = response;
                this.setState({
                    tripData: tripData,
                    showLocationSearch: false
                }, this.updateDirections);
            });
        }
    };

    render() {
        return (
            <MyTripContext.Provider
                value={{
                    ...this.state,
                    addLocation: this.addLocation,
                    removeLocation: this.removeLocation,
                    addDay: this.addDay,
                    removeDay: this.confirmRemoveDay,
                    setSelectedDay: this.setSelectedDay,
                    setStartLocation: this.setStartLocation,
                    openTrip: this.openTrip,
                    openTutorial: this.openTutorial,
                    showSearchBox: this.showSearchBox,
                    moveItemDown: this.moveItemDown,
                    moveItemUp: this.moveItemUp,
                    loadPremadeItinerary: this.loadPremadeItinerary
                }}>
                    <>
                        <Notifications />
                        {this.props.children}
                    </>
            </MyTripContext.Provider>
        );
    }
}

MyTripProvider.propTypes = {
    children: PropTypes.any,
};
