/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import ContextWrapper from "./src/context/ContextWrapper";

export const onServiceWorkerUpdateReady = () => window.location.reload();

export const wrapPageElement = ({ element, props }) => (
    <ContextWrapper {...props}>{element}</ContextWrapper>
);
