import React from 'react';
import './style.less';
import {navigate} from "gatsby";
import {Button, Form, Input, Select} from "antd";
import Link from "../Link";
import MyTripSlides from "../MyTripSlides";
import ItineraryItem from "./ItineraryItem";
import {MyTripContext} from "../../context/MyTripContext";
import language from "../../../data/language.yaml";
import {minutesToTime} from "../../services/helpers";

class MyTrip extends React.Component {

    /*
        TripData {
          day1: {
            location1: {

            },
            ...
          },
          ...
        }
     */
    constructor(props) {
        super(props);
        this.state = {
            startLocationText: ''
        };
    }

    openMyTrip = () => {
        this.context.openTrip();
    };

    addDay = () => {
        this.context.addDay();
    };

    toggleTutorial = (e) => {
        e.preventDefault();
        this.context.openTutorial();
    };

    removeDay = () => {
        // confirm then send to context
        this.context.removeDay();
    };

    changeSelectedDay = (day) => {
        this.context.setSelectedDay(day);
    };

    editStart = () => {
        this.context.showSearchBox();
    };

    getDayTotal = () => {
        let days = this.context.tripData.length;
        if (days > 1) {
            return `${days} ${language.myTrip.days[this.props.locale].toLowerCase()}`;
        } else {
            return `${days} ${language.myTrip.day[this.props.locale].toLowerCase()}`;
        }
    };

    getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position => {
                if (position) {
                    this.context.setStartLocation(null, position.coords.latitude, position.coords.longitude);
                }
            }));
        }
    };

    sendStartLocation = () => {
        if (this.state.startLocationText) {
            this.context.setStartLocation(this.state.startLocationText);
        }
    };

    render() {
        let dayItemCount = '';
        if (this.context.tripData[this.context.selectedDay].length) {
            if (this.context.selectedDay === 0) {
                if (this.context.tripData[this.context.selectedDay].length === 1) {
                    dayItemCount = '';
                } else {
                    dayItemCount = this.context.tripData[this.context.selectedDay].length - 1;
                }
            } else {
                dayItemCount = this.context.tripData[this.context.selectedDay].length;
            }
        }

        let topNoteContent = '';
        switch (this.context.tripData[0].length) {
            case 0:
            case 1:
                topNoteContent = language.myTrip.startNote[this.props.locale];
                break;
            case 2:
            default:
                if (this.context.tripData[0][0].hasOwnProperty('empty')) {
                    topNoteContent = language.myTrip.enterStartLocation[this.props.locale];
                }
                break;
        }
        let topNote = topNoteContent ? <p style={{fontSize: '12px', lineHeight: '18px'}} className="enter-start-helper">{topNoteContent}</p> : '';

        let firstItineraryItem = '';
        if (this.context.selectedDay === 0 && this.context.tripData[0].length >= 1 && !this.context.tripData[0][0].hasOwnProperty('empty')) {
            // no starting location is set for day 1
            firstItineraryItem = <li className={"itinerary-item" + (this.context.tripData[0][0].empty ? " empty" : "")} data-color="gray">
                <span className=""/>
                <span className="left big">
                    <Link onClick={this.editStart}>{language.myTrip.edit[this.props.locale]}</Link>
                </span>
                <span className="right">
                    <span className="starting">{language.myTrip.startingLocation[this.props.locale]}</span>
                    <span className="post-code">{this.context.tripData[this.context.selectedDay][0].text}</span>
                </span>
            </li>;
        }
        return (
            <React.Fragment>
                <div className="myTripWrapper">
                    <span className="button-wrap">
                        <Button className="my-trip-trigger" onClick={this.openMyTrip} open={this.context.myTripOpen}
                                data-count={dayItemCount}>
                            <span className="icon"/>
                            {language.myTrip[this.props.locale]}
                        </Button>
                    </span>
                    <div className="my-trip-wrapper" open={this.context.myTripOpen}>
                        <div className="title-bar">
                            <span className="title">{language.myTrip[this.props.locale]}</span>
                            <div className="trip-help-button">
                                <Button className="trip-help" onClick={this.toggleTutorial}>
                                    <span className="icon">?</span>
                                    {language.myTrip.help[this.props.locale]}
                                </Button>
                            </div>
                            <div className="trip-info">
                                <span className="distance">
                                    {Math.floor((this.context.totalDistance / 1000) * 0.62137119223)} {language.myTrip.miles[this.props.locale]}
                                </span>
                                <span className="days">{this.getDayTotal()}</span>
                                <Form layout="inline">
                                    <Select placeholder="Select day" onChange={this.changeSelectedDay}
                                            value={this.context.selectedDay} dropdownAlign={{offset: [0, 0]}}>
                                        {this.context.tripData.length && this.context.tripData.map((day, x) => {
                                            return (
                                                <Select.Option key={x} value={x}>{language.myTrip.day[this.props.locale]} {x + 1}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                    <Link className="btn btn-secondary btn-small" onClick={this.addDay}>
                                        <span>{language.myTrip.add[this.props.locale]}</span>
                                        <span className="icon add"/>
                                    </Link>
                                </Form>
                            </div>
                        </div>
                        <div className="trip-content">
                            {this.context.tutorialOpen ?
                                <>
                                    <MyTripSlides/>
                                    <p style={{textAlign: 'center'}}>
                                        <a href="##" className="btn btn-secondary btn-small" onClick={this.toggleTutorial}>
                                            <span>{language.myTrip.getStarted[this.props.locale]}</span>
                                            <span className="icon arrow"></span>
                                        </a>
                                    </p>
                                </>
                                :
                                <>
                                    {this.context.showLocationSearch ?
                                        <>
                                            {topNote}

                                            <Form layout="inline">
                                                <Input
                                                    placeholder={language.myTrip.locateMe[this.props.locale]}
                                                    onChange={(e) => {
                                                        this.setState({startLocationText: e.target.value})
                                                    }}
                                                />
                                                <Button className="btn btn-white btn-dot" onClick={this.getUserLocation}>
                                                    <span className="icon locate"/>
                                                </Button>
                                                <Button className="btn btn-white btn-small" onClick={this.sendStartLocation}>
                                                    {language.myTrip.search[this.props.locale]}
                                                    <span className="icon arrow"/>
                                                </Button>
                                            </Form>
                                        </> : ''}

                                    <div className="itinerary-wrapper">
                                        <div className="day">
                                            <span className="title">{language.myTrip.day[this.props.locale]} {this.context.selectedDay + 1}</span>
                                            {this.context.selectedDay > 0 ?
                                                <Link className="btn btn-transparent btn-small"
                                                      onClick={this.removeDay}>
                                                    <span>{language.myTrip.remove[this.props.locale]}</span>
                                                    <span className="icon"/>
                                                </Link>
                                                : ''}
                                        </div>
                                        <ul className="itinerary">
                                            {firstItineraryItem}

                                            {
                                                // loop through locations, fetching out the route information as well
                                            }
                                            {this.context.tripData[this.context.selectedDay].map((location, index) => {
                                                // Skip the first index on day 1
                                                if (index === 0 && this.context.selectedDay === 0) {
                                                    return '';
                                                }
                                                // Change the color based on the type
                                                let color = 'yellow'
                                                switch (location.parent_location_type) {
                                                    case 'attractions':
                                                    case 'atyniad':
                                                        color = 'orange';
                                                        break;
                                                    case 'accommodation':
                                                    case 'llety':
                                                        color = 'teal';
                                                        break;
                                                    case 'eateries':
                                                    case 'bwytai':
                                                        color = 'yellow';
                                                        break;
                                                    case 'retail-hire':
                                                    case 'manwerthu':
                                                        color = 'gray';
                                                        break;
                                                    default :
                                                        color = 'primary';
                                                        break;
                                                }
                                                // Work out the distance and time, they are in km and seconds
                                                let tripTime = false;
                                                if (location.route !== null) {
                                                    tripTime = minutesToTime(location.route.duration / 60, this.props.locale);
                                                }
                                                // Work out the link and target from the member info
                                                // member level 3 is external link
                                                let link = `${location.member.path}?location=${location.wordpress_id}`;
                                                if (location.member.membership_level === "3") {
                                                    link = location.member.website_url
                                                }

                                                let hasPrevious = false;
                                                let hasNext     = false;

                                                if (typeof this.context.tripData[this.context.selectedDay][index + 1] !== 'undefined') {
                                                    hasNext = true;
                                                }
                                                if (typeof this.context.tripData[this.context.selectedDay][index - 1] !== 'undefined') {
                                                    // One day 1, we have the start location as a previous check this is not the next one
                                                    if (this.context.selectedDay === 0 && index === 1) {
                                                        hasPrevious = false;
                                                    } else {
                                                        hasPrevious = true;
                                                    }
                                                }

                                                // if this is day 1, skip the first item
                                                let memberDuration = '';
                                                if (location.member.average_visit_duration) {
                                                    memberDuration = minutesToTime(location.member.average_visit_duration, this.props.locale);
                                                }

                                                return (
                                                    <ItineraryItem
                                                        key={index}
                                                        color={color}
                                                        name={location.name}
                                                        link={link}
                                                        image={location.member.featured_image.sizes.thumbnail}
                                                        duration={memberDuration}
                                                        distance={tripTime}
                                                        canRemove={true}
                                                        hasNext={hasNext}
                                                        hasPrevious={hasPrevious}
                                                        onRemove={() => {
                                                            this.context.removeLocation(index)
                                                        }}
                                                        onMoveDown={() => {
                                                            this.context.moveItemDown(index)
                                                        }}
                                                        onMoveUp={() => {
                                                            this.context.moveItemUp(index)
                                                        }}
                                                        onDuplicate={() => {
                                                            this.context.addLocation(location.wordpress_id)
                                                        }}
                                                    />
                                                )
                                            })}
                                        </ul>
                                    </div>
                                    <div className="add-member-to-itinerary" style={{textAlign: 'center', marginTop: '30px'}}>
                                        <Link className="btn btn-transparent btn-small" onClick={() => {
                                            this.context.openTrip();
                                            let path = this.props.locale === 'cy/#map' ? '/cy/#map' : '/#map';
                                            // console.log(this.context.tripData);
                                            // let itinItems = this.context.tripData[this.context.selectedDay];
                                            // for (let i = itinItems.length - 1; i >= 0; i--) {
                                            //     if (itinItems[i].wordpress_id) {
                                            //         path = '?location=' + itinItems[i].wordpress_id;
                                            //         break;
                                            //     }
                                            // }
                                            navigate(path);
                                        }}>
                                            <span>{language.myTrip.addLocations[this.props.locale]}</span>
                                            <span className="icon arrow"/>
                                        </Link>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="controls">
                            {this.context.tutorialOpen ? '' :
                                <Link className="btn btn-white btn-small" onClick={() => {
                                    this.context.openTrip();
                                    this.props.locale === 'cy' ? navigate('/cy/fy-nhaith/') : navigate('/my-trip')
                                }}>
                                    <span>{language.myTrip.viewMyTrip[this.props.locale]}</span>
                                    <span className="icon arrow"/>
                                </Link>
                            }
                            <Button className="menu-close" onClick={this.openMyTrip} open={this.context.myTripOpen}>
                                <span className="icon"/>
                                {language.close[this.props.locale]}
                            </Button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

MyTrip.contextType = MyTripContext;

export default MyTrip
