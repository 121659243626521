import React from 'react';
import { Link as GatsbyLink } from "gatsby"

const Link = ({ children, to, activeClassName, ...other }) => {

    const internal = /^\/(?!\/)/.test(to)

    if (internal) {
        // add a trailing slash, but only if there is not a query string!
        if (to.indexOf('?')===-1 && to.substr(-1) !== '/') to += '/';
        return (
            <GatsbyLink to={to} activeClassName={activeClassName} {...other}>
                {children}
            </GatsbyLink>
        )
    }
    return (
        <a href={to} {...other} target="_blank" rel="noreferrer">
            {children}
        </a>
    )
}

export default Link
