import React from 'react';

import MyTrip from "../components/MyTrip";
import {MyTripContext, MyTripProvider} from "./MyTripContext";
import {LocaleContext, LocaleProvider} from "./LocaleContext";

const ContextWrapper = ({ children }) => (
    <LocaleProvider>
        <LocaleContext.Consumer>
            {({ locale }) => (
                <MyTripProvider locale={locale}>
                    <MyTripContext.Consumer>
                        {({ tripData }) => (
                            <>
                                {children}
                                <MyTrip locale={locale}/>
                            </>
                        )}
                    </MyTripContext.Consumer>
                </MyTripProvider>
            )}
        </LocaleContext.Consumer>
    </LocaleProvider>
);

export default ContextWrapper;
