import React from 'react';
import PropTypes from 'prop-types';

// Context is made up of two things
// Provider - Single as close to top level as possible
// Consumer - Multiple have multiple consumers
export const LocaleContext = React.createContext();

export class LocaleProvider extends React.Component {
    state = {
        locale: 'en'
    };

    setLocale = (locale) => {
        // console.log('setting lang')
        this.setState({
            locale: locale
        })
    };

    render() {
        return (
            <LocaleContext.Provider
                value={{
                    ...this.state,
                    setLocale: this.setLocale,
                }}>
                {this.props.children}
            </LocaleContext.Provider>
        );
    }
}

LocaleProvider.propTypes = {
    children: PropTypes.any,
};
